import React, { Fragment, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import InputLabel from '../../commons/Inputs/InputLabel';
import InputFile from '../../commons/Inputs/InputFile';
import Button from '../../commons/Button';
import ColorPicker from '../../commons/ColorPicker';
import MapComponent from '../../components/MapComponent';
import SelectInput from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormWrapper } from './walletForm.styles';
import {
  walletFormPropsChanged,
  walletFormCreateWallet,
  walletFormEditWallet,
} from '../../actions';
import { CircularProgress } from '@material-ui/core';
import defaultLogo from '../../assets/images/sm-thumbnail.png';
import cover from '../../assets/images/lg-thumbnail.png';
import { loadMapApi } from '../../utils/GoogleMapsUtils';
import { FromWalletProps } from './walletForm.types';
import { RootState } from '../../reducers';

import { Icon } from 'react-icons-kit';
import WireframeIPhone from '../../components/WireframeIPhone';

import Wireframe from '../../components/Wireframe';
import invisible from '../../assets/images/invisible.svg';
import visible from '../../assets/images/visibility.svg';

import { ic_warning } from 'react-icons-kit/md/ic_warning';
import { ic_add_circle_outline } from 'react-icons-kit/md/ic_add_circle_outline';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { FormControlLabel } from '@material-ui/core';

import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import axios from 'axios';
import PassPermissionSelector from '../../components/PermissionSelector/indes';
import ExpirationDateSection from '../../components/ExpirationDateSection';
import { getImageErrorMessage } from '../../utils/common';

import BarcodeTypeSelection from '../../components/BarcodeSelection';
import { useIntl } from 'react-intl';

const WalletForm: React.FC<FromWalletProps> = (props) => {
  const {
    id,
    name,
    logo,
    icon,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    address,
    facebook,
    instagram,
    linkedin,
    website,
    locations,
    businessPhone,
    servicePhone,
    openingHours,
    descriptions,
    additionalContacts,
    username,
    usernameSMS,
    passwordSMS,
    emailingConfig,
    email,
    login,
    communicationEmail,
    password,
    confirmPassword,
    button,
    loading,
    title,
    barcodeType,
    primaryFields,
    label,
    value,
    labelPersonnalizedText,
    valuePersonnalizedText,

    //errors
    businessPhoneError,
    nameError,
    logoError,
    iconError,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    addressError,
    openingHoursError,
    facebookError,
    instagramError,
    linkedinError,
    websiteError,
    usernameError,
    emailError,
    passwordError,
    confirmPasswordError,
    descriptionError,
    additionalContactError,
    usernameSMSerror,
    passwordSMSerror,
    contactSectionHasErrors,
    errorModal,
    walletInfoSectionHasErrors,
    styleSectionHasErrors,
    accountSectionHasErrors,
    descriptionSectionHasErrors,
    smsErrors,
    allowedPasses,
  } = props;
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const handleEventChange = (event: any) => {
    const { name, value } = event.target;
    if (name === 'username') {
      props.walletFormPropsChanged(name, value.replaceAll(' ', '_'));
    } else {
      props.walletFormPropsChanged(name, value);
    }
  };

  const [switchPreview, setSwitchPreview] = React.useState<string>('android');

  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview((event.target as HTMLInputElement).value);
  };

  const handleColorChange = (color: any, prop: string) => {
    props.walletFormPropsChanged(prop, color);
  };

  const handleLocationEventChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    let locationClone = [...locations];
    if (type === 'lat') {
      locationClone[0].latitude = Number(event.target.value);
    } else {
      locationClone[0].longitude = Number(event.target.value);
    }
    props.walletFormPropsChanged('locations', locationClone);
  };

  const handleLocationChange = (value: any, prop: string, type: string) => {
    let locationClone = [...locations];
    if (type === 'latLng') {
      locationClone[0].latitude = value.lat;
      locationClone[0].longitude = value.lng;
    } else if (type === 'description') {
      locationClone[0].relevantText = value;
    }
    props.walletFormPropsChanged(prop, locationClone);
  };
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);

  useEffect(() => {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos: any) {
      var crd = pos.coords;
      if (!location.pathname.includes('edit')) {
        handleLocationChange(
          { lat: crd.latitude, lng: crd.longitude },
          'locations',
          'latLng'
        );
      }
    }

    function error(err: any) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
  }, []);

  useEffect(() => {
    executeScroll();
  }, [buttonClicked, usernameSMSerror, passwordSMSerror]);

  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      props.walletFormPropsChanged(`${prop}Error`, getImageErrorMessage(file));
      return;
    }
    props.walletFormPropsChanged(prop, file);
  };

  if (location.pathname.includes('edit') && id === null) {
    history.push('/wallets');
  }
  const handleClickSave = () => {
    if (button === 'Modifier') {
      props.walletFormEditWallet(props, () => history.push('/wallets'));
    } else {
      props.walletFormCreateWallet(props, () => {
        return history.push('/wallets');
      });
    }
    setButtonClicked(!buttonClicked);
  };

  const handleEmailingConfigChanged = (val: string) => {
    props.walletFormPropsChanged('emailingConfig', val);
  };
  const handleDescriptionTitleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('descriptionError', '');
    let descriptionsClone = [...descriptions];
    descriptionsClone[index].title = e.target.value;
    props.walletFormPropsChanged('descriptions', descriptionsClone);
  };

  const handleDescriptionValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('descriptionError', '');
    let descriptionsClone = [...descriptions];
    descriptionsClone[index].value = e.target.value;
    props.walletFormPropsChanged('descriptions', descriptionsClone);
  };

  const handleAddDescriptionChange = () => {
    if (
      descriptions[descriptions.length - 1].title === '' ||
      descriptions[descriptions.length - 1].value === ''
    ) {
      props.walletFormPropsChanged(
        'descriptionError',
        'La description ' +
          descriptions.length +
          " doit être remplie avant d'en ajouter une nouvelle."
      );
    } else {
      props.walletFormPropsChanged('descriptionError', '');
      let descriptionsClone = [...descriptions];
      descriptionsClone.push({ title: '', value: '' });
      props.walletFormPropsChanged('descriptions', descriptionsClone);
    }
  };

  const handleAdditionalContactValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('additionalContactError', '');
    let additionalContactClone = [...additionalContacts];
    additionalContactClone[index].value = e.target.value;
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleDeleteDescriptionChange = (index: number) => {
    let descriptionsClone = [...descriptions];
    descriptionsClone.splice(index, 1);
    props.walletFormPropsChanged('descriptions', descriptionsClone);
  };

  const handleDeleteAdditionalContactChange = (index: number) => {
    let additionalContactClone = [...additionalContacts];
    additionalContactClone.splice(index, 1);
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleAdditionalContactTypeChnage = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    index: number
  ) => {
    props.walletFormPropsChanged('additionalContactError', '');
    let additionalContactClone = [...additionalContacts];
    additionalContactClone[index].kind = e.target.value;
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleAdditionalContactTitleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('additionalContactError', '');
    let additionalContactClone = [...additionalContacts];
    additionalContactClone[index].title = e.target.value;
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleAddAdditionalContactChange = () => {
    if (
      additionalContacts[additionalContacts.length - 1].title === '' ||
      additionalContacts[additionalContacts.length - 1].value === ''
    ) {
      props.walletFormPropsChanged(
        'additionalContactError',
        'Contact' +
          descriptions.length +
          " doit être remplie avant d'en ajouter une nouvelle."
      );
    } else {
      props.walletFormPropsChanged('additionalContactError', '');
      let additionalContactClone = [...additionalContacts];
      additionalContactClone.push({ title: '', kind: 'URL', value: '' });
      props.walletFormPropsChanged(
        'additionalContacts',
        additionalContactClone
      );
    }
  };

  //password
  const [showpassword, setShowpassword] = useState<boolean>(false);
  const [showPasswordSMS, setShowPasswordSMS] = useState<boolean>(false);

  const [showConfirmpassword, setShowConfirmpassword] = useState<boolean>(
    false
  );
  const [dycriptedPassword, setdycriptedPassword] = useState<
    string | undefined
  >('');

  const getPassword = () => {
    const URL = process.env.REACT_APP_API_URL;
    const requestURL = `${URL}/settings/${id}/password/`;
    axios.get(requestURL).then(
      (response) => {
        setdycriptedPassword(response.data.response.data.decryptedPassword);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const contactRef: any = useRef(null);
  const descriptionRef: any = useRef(null);
  const styleRef: any = useRef(null);
  const walletInfoRef: any = useRef(null);
  const accountRef: any = useRef(null);
  const smsRef: any = useRef(null);

  const getErrorsLocation = () => {
    if (accountSectionHasErrors) return accountRef;
    if (usernameSMSerror || passwordSMSerror) return smsRef;
    if (walletInfoSectionHasErrors) return walletInfoRef;
    if (contactSectionHasErrors) return contactRef;
    if (descriptionSectionHasErrors) return descriptionRef;
    if (styleSectionHasErrors) return styleRef;
    return null;
  };
  const executeScroll = () => {
    let elemPosition = getErrorsLocation();

    if (elemPosition && elemPosition.current) {
      elemPosition.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  };

  return (
    <FormWrapper>
      <Fragment>
        <div className='form-header'>
          <span className='title'>{title}</span>
          <div className='action'>
            {(businessPhoneError ||
              nameError ||
              logoError ||
              iconError ||
              stripError ||
              labelColorError ||
              backgroundColorError ||
              foregroundColorError ||
              addressError ||
              openingHoursError ||
              facebookError ||
              instagramError ||
              linkedinError ||
              websiteError ||
              usernameError ||
              usernameSMSerror ||
              passwordSMSerror ||
              emailError ||
              passwordError ||
              confirmPasswordError ||
              descriptionError ||
              additionalContactError) && (
              <div className='error-icon'>
                <Icon
                  icon={ic_warning}
                  size={24}
                  style={{ color: '#FF5078' }}
                />
              </div>
            )}
            <Button styled='secondary' onClick={() => history.push('/wallets')}>
              Annuler
            </Button>{' '}
            <Button
              styled='primary'
              onClick={handleClickSave}
              disabled={loading ? true : false}
            >
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                button
              )}
            </Button>
          </div>
        </div>
        {/* <Modal
          open={errorModal}
          onClose={() => props.walletFormPropsChanged('errorModal', false)}
          height={800}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '20px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                size={32}
                icon={ic_error_outline}
                style={{ color: '#df2e2e', marginRight: '10px' }}
              />
              <h3>Erreur lors de la création du wallet.</h3>
            </div>
            <div>
              <p>{businessPhoneError}</p>
              <p>{nameError} </p>
              <p>{logoError} </p>
              <p>{iconError} </p>
              <p>{stripError} </p>
              <p>{labelColorError} </p>
              <p>{backgroundColorError} </p>
              <p>{foregroundColorError} </p>
              <p>{addressError} </p>
              <p>{openingHoursError} </p>
              <p>{facebookError} </p>
              <p>{instagramError} </p>
              <p>{linkedinError} </p>
              <p>{websiteError} </p>
              <p>{usernameError} </p>
              <p>{emailError} </p>
              <p>{passwordError}</p>
              <p>{descriptionError} </p>
              <p>{additionalContactError}</p>
            </div>
            <Button
              styled='primary'
              onClick={() => props.walletFormPropsChanged('errorModal', false)}
            >
              Ok
            </Button>
          </div>
        </Modal> */}
        <div className='container'>
          <div className='form-container'>
            <div
              className='section'
              ref={(el: any) => {
                accountRef.current = el;
              }}
            >
              <h3 className='section-title'>Information du compte</h3>
              {button !== 'Modifier' && (
                <Fragment>
                  <div className='section-row'>
                    <InputLabel
                      label='Email*'
                      name='email'
                      value={email}
                      error={emailError}
                      placeholder='Email'
                      onChange={(e) => handleEventChange(e)}
                    />
                    <InputLabel
                      label="Nom d'utilisateur*"
                      name='username'
                      value={username}
                      error={usernameError}
                      placeholder='Login'
                      onChange={(e) => handleEventChange(e)}
                    />
                  </div>
                  <div className='section-row'>
                    <div className='password-section'>
                      <InputLabel
                        type={showpassword ? 'text' : 'password'}
                        label='Mot de passe*'
                        name='password'
                        value={password}
                        error={passwordError}
                        placeholder='Mot de passe'
                        // disabled={button === 'Modifier'}
                        onChange={(e) => handleEventChange(e)}
                      />
                      <span className='icon'>
                        {showpassword ? (
                          <img
                            src={visible}
                            alt='visible'
                            onClick={() => {
                              setShowpassword(false);
                            }}
                          />
                        ) : (
                          <img
                            src={invisible}
                            alt='invisible'
                            onClick={() => {
                              setShowpassword(true);
                            }}
                          />
                        )}
                      </span>
                    </div>
                    <div className='password-section'>
                      <InputLabel
                        type={showConfirmpassword ? 'text' : 'password'}
                        label='Confirmez le mot de passe*'
                        name='confirmPassword'
                        value={confirmPassword}
                        error={confirmPasswordError}
                        placeholder='Confirmez le mot de passe'
                        // disabled={button === 'Modifier'}
                        onChange={(e) => handleEventChange(e)}
                      />
                      <span className='icon'>
                        {showConfirmpassword ? (
                          <img
                            src={visible}
                            alt='visible'
                            onClick={() => {
                              setShowConfirmpassword(false);
                            }}
                          />
                        ) : (
                          <img
                            src={invisible}
                            alt='invisible'
                            onClick={() => {
                              setShowConfirmpassword(true);
                            }}
                          />
                        )}
                      </span>
                    </div>
                  </div>{' '}
                  <span className='label'>Pass autorisée</span>
                  <PassPermissionSelector action='create'></PassPermissionSelector>
                </Fragment>
              )}
              {button === 'Modifier' && (
                <Fragment>
                  <div className='section-row'>
                    <InputLabel
                      label="Nom d'utilisateur"
                      name='username'
                      value={login}
                      placeholder="Nom d'utilisateur"
                      disabled
                    />
                    <div className='password-section'>
                      <InputLabel
                        label='Mot de passe'
                        name='mdp'
                        value={
                          dycriptedPassword ? dycriptedPassword : '**********'
                        }
                        placeholder='Mot de passe'
                        disabled
                      />
                      <span className='icon'>
                        {showpassword ? (
                          <img
                            src={visible}
                            alt='visible'
                            onClick={() => {
                              setShowpassword(false);
                              setdycriptedPassword(undefined);
                            }}
                          />
                        ) : (
                          <img
                            src={invisible}
                            alt='invisible'
                            onClick={() => {
                              setShowpassword(true);
                              getPassword();
                            }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='section-row'>
                    <InputLabel
                      label='Email de Communication'
                      name='communicationEmail'
                      value={communicationEmail}
                      placeholder='Email de Communication'
                      disabled
                    />
                    <InputLabel
                      label='Email'
                      name='email'
                      value={email}
                      placeholder='email'
                      disabled
                    />
                  </div>
                  <span className='label'>Pass autorisée</span>

                  <PassPermissionSelector action='update'></PassPermissionSelector>
                </Fragment>
              )}
            </div>

            <div
              className='section'
              ref={(el: any) => {
                smsRef.current = el;
              }}
            >
              <h3 className='section-title'>Configuration du compte SMS</h3>

              <Fragment>
                <div className='section-row'>
                  <InputLabel
                    label="Nom d'utilisateur"
                    name='usernameSMS'
                    value={usernameSMS}
                    error={usernameSMSerror}
                    placeholder="Nom d'utilisateur"
                    autoComplete='new-password'
                    onChange={(e) => handleEventChange(e)}
                  />

                  <div className='password-section'>
                    <InputLabel
                      type={showPasswordSMS ? 'text' : 'password'}
                      label='Mot de passe'
                      name='passwordSMS'
                      error={passwordSMSerror}
                      value={passwordSMS}
                      placeholder='Mot de passe'
                      autoComplete='new-password'
                      // disabled={button === 'Modifier'}
                      onChange={(e) => handleEventChange(e)}
                    />
                    <span className='icon'>
                      {showPasswordSMS ? (
                        <img
                          src={visible}
                          alt='visible'
                          onClick={() => {
                            setShowPasswordSMS(false);
                          }}
                        />
                      ) : (
                        <img
                          src={invisible}
                          alt='invisible'
                          onClick={() => {
                            setShowPasswordSMS(true);
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </Fragment>
            </div>

            <div className='section' style={{ paddingBottom: '50px' }}>
              <h3 className='section-title'>Configuration du Emailing</h3>

              <Fragment>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <RadioGroup
                    value={emailingConfig}
                    onChange={(e) => {
                      handleEmailingConfigChanged(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value='SES'
                      control={<Radio />}
                      label='Amazon SES'
                    />
                    <FormControlLabel
                      value='smtp'
                      control={<Radio />}
                      label='SMTP'
                    />
                  </RadioGroup>
                </div>
              </Fragment>
            </div>

            <div
              className='section'
              ref={(el: any) => {
                walletInfoRef.current = el;
              }}
            >
              <h3 className='section-title'>Information du wallet</h3>
              <BarcodeTypeSelection
                setSelectedBarcode={(barcodeType) => {
                  props.walletFormPropsChanged('barcodeType', barcodeType);
                }}
                barcodeType={barcodeType}
              ></BarcodeTypeSelection>
              <div className='section-row'>
                <InputLabel
                  label='Enseigne*'
                  name='name'
                  value={name}
                  error={nameError}
                  placeholder='Enseigne'
                  onChange={(e) => handleEventChange(e)}
                />
              </div>
              <div className='section-row'>
                <InputLabel
                  label='Téléphone commercial*'
                  name='businessPhone'
                  error={businessPhoneError}
                  value={businessPhone}
                  // error={nameError}
                  placeholder='Téléphone commercial'
                  onChange={(e) => handleEventChange(e)}
                />
                <InputLabel
                  label='Téléphone S.A.V*'
                  name='servicePhone'
                  value={servicePhone}
                  // error={nameError}
                  placeholder='Téléphone S.A.V'
                  onChange={(e) => handleEventChange(e)}
                />
              </div>
              <div className='section-row'>
                <InputLabel
                  label='Site web'
                  name='website'
                  value={website}
                  error={websiteError}
                  placeholder='https://'
                  onChange={(e) => handleEventChange(e)}
                />

                <InputLabel
                  label='Facebook'
                  name='facebook'
                  value={facebook}
                  error={facebookError}
                  placeholder='https://'
                  onChange={(e) => handleEventChange(e)}
                />
              </div>
              <div className='section-row'>
                <InputLabel
                  label='Instagram'
                  name='instagram'
                  value={instagram}
                  error={instagramError}
                  placeholder='https://'
                  onChange={(e) => handleEventChange(e)}
                />
                <InputLabel
                  label='Linkedin'
                  name='linkedin'
                  value={linkedin}
                  error={linkedinError}
                  placeholder='https://'
                  onChange={(e) => handleEventChange(e)}
                />
              </div>
              <div className='section-row'>
                <InputLabel
                  inputType='textArea'
                  label='Adresse*'
                  name='address'
                  rows={10}
                  height={158}
                  value={address}
                  error={addressError}
                  placeholder='Adresse'
                  onChange={(e) => handleEventChange(e)}
                />

                <InputLabel
                  label="Horaires d'ouvertures*"
                  inputType='textArea'
                  rows={10}
                  height={158}
                  name='openingHours'
                  value={openingHours}
                  error={openingHoursError}
                  placeholder="Horaires d'ouvertures"
                  onChange={(e) => handleEventChange(e)}
                />
              </div>
              <div className='iosheaderSection '>
                <p>
                  {intl.formatMessage({
                    id: 'settings.label.headerFields',
                  })}
                </p>
                <div className='section-row '>
                  <div className='select' style={{ width: '350px' }}>
                    <p>
                      {intl.formatMessage({
                        id: 'settings.label.firstField',
                      })}
                    </p>
                    <SelectInput
                      label={intl.formatMessage({
                        id: 'settings.label.firstField',
                      })}
                      onChange={(e) => {
                        handleEventChange(e);
                      }}
                      defaultValue={'REWARDS'}
                      value={label}
                      name='label'
                    >
                      <MenuItem value='REWARDS'>
                        {intl.formatMessage({
                          id: 'settings.label.rewards',
                        })}
                      </MenuItem>
                      <MenuItem value='TYPE'>
                        {intl.formatMessage({
                          id: 'settings.label.type',
                        })}
                      </MenuItem>
                      <MenuItem value='FULLNAME'>
                        {intl.formatMessage({
                          id: 'settings.label.fullName',
                        })}
                      </MenuItem>

                      <MenuItem value='TEXT'>
                        {intl.formatMessage({
                          id: 'settings.label.text',
                        })}
                      </MenuItem>

                      <MenuItem value='DISABLED'>
                        {intl.formatMessage({
                          id: 'settings.label.disabled',
                        })}
                      </MenuItem>
                    </SelectInput>
                  </div>
                  {label === 'TEXT' ? (
                    <div style={{ width: '350px' }}>
                      <InputLabel
                        label={intl.formatMessage({
                          id: 'settings.label.personalizedText',
                        })}
                        name='labelPersonnalizedText'
                        value={labelPersonnalizedText}
                        onChange={(e) => {
                          handleEventChange(e);
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ width: '350px' }} />
                  )}
                </div>

                <div className='section-row'>
                  <div className='select' style={{ width: '350px' }}>
                    <p>
                      {intl.formatMessage({
                        id: 'settings.label.secondField',
                      })}
                    </p>
                    <SelectInput
                      label={intl.formatMessage({
                        id: 'settings.label.secondField',
                      })}
                      onChange={(e) => {
                        handleEventChange(e);
                      }}
                      fullWidth={true}
                      defaultValue={'TYPE'}
                      value={value}
                      name='value'
                    >
                      <MenuItem value='REWARDS'>
                        {intl.formatMessage({
                          id: 'settings.label.rewards',
                        })}
                      </MenuItem>
                      <MenuItem value='TYPE'>
                        {intl.formatMessage({
                          id: 'settings.label.type',
                        })}
                      </MenuItem>
                      <MenuItem value='FULLNAME'>
                        {intl.formatMessage({
                          id: 'settings.label.fullName',
                        })}
                      </MenuItem>

                      <MenuItem value='TEXT'>
                        {intl.formatMessage({
                          id: 'settings.label.text',
                        })}
                      </MenuItem>

                      <MenuItem value='DISABLED'>
                        {intl.formatMessage({
                          id: 'settings.label.disabled',
                        })}
                      </MenuItem>
                    </SelectInput>
                  </div>
                  {value === 'TEXT' ? (
                    <div style={{ width: '350px' }}>
                      <InputLabel
                        label={intl.formatMessage({
                          id: 'settings.label.personalizedText',
                        })}
                        fullWitdh={true}
                        name='valuePersonnalizedText'
                        value={valuePersonnalizedText}
                        onChange={(e) => {
                          handleEventChange(e);
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ width: '350px' }} />
                  )}
                </div>
              </div>
              <div className='iosheaderSection ' style={{ width: '350px' }}>
                <p>
                  {intl.formatMessage({
                    id: 'settings.label.primaryFields',
                  })}
                </p>
                <div className='section-row '>
                  <div className='select'>
                    <p>
                      {intl.formatMessage({
                        id: 'settings.label.firstField',
                      })}
                    </p>
                    <SelectInput
                      label={intl.formatMessage({
                        id: 'settings.label.firstField',
                      })}
                      onChange={(e) => {
                        const obj = {
                          ...primaryFields,
                          firstField: e.target.value,
                        };
                        props.walletFormPropsChanged('primaryFields', obj);
                      }}
                      defaultValue={'CREATION_DATE'}
                      value={primaryFields.firstField}
                      name='firstField'
                    >
                      <MenuItem value='CREATION_DATE'>
                        {intl.formatMessage({
                          id: 'settings.label.creationDate',
                        })}
                      </MenuItem>
                      <MenuItem value='DISABLED'>
                        {intl.formatMessage({
                          id: 'settings.label.disabled',
                        })}
                      </MenuItem>
                    </SelectInput>
                  </div>
                </div>

                <div className='section-row'>
                  <div className='select'>
                    <p>
                      {intl.formatMessage({
                        id: 'settings.label.secondField',
                      })}
                    </p>
                    <SelectInput
                      label={intl.formatMessage({
                        id: 'settings.label.secondField',
                      })}
                      onChange={(e) => {
                        const obj = {
                          ...primaryFields,
                          secondField: e.target.value,
                        };
                        props.walletFormPropsChanged('primaryFields', obj);
                      }}
                      defaultValue={'TYPE'}
                      value={primaryFields.secondField}
                      name='secondField'
                    >
                      <MenuItem value='REWARDS'>
                        {intl.formatMessage({
                          id: 'settings.label.rewards',
                        })}
                      </MenuItem>

                      <MenuItem value='DISABLED'>
                        {intl.formatMessage({
                          id: 'settings.label.disabled',
                        })}
                      </MenuItem>
                    </SelectInput>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='section'
              ref={(el: any) => {
                contactRef.current = el;
              }}
            >
              <h3
                className='section-title'
                ref={(el: any) => {
                  contactRef.current = el;
                }}
              >
                {' '}
                Contact supplémentaire
              </h3>
              {additionalContacts.map((el: any, index: number) => (
                <div key={index} className='additionalContact'>
                  <div className='additionalContact-title'>
                    <h3>Contact supplémentaire {index + 1}</h3>
                    {index > 0 && (
                      <span
                        onClick={() =>
                          handleDeleteAdditionalContactChange(index)
                        }
                      >
                        <Icon
                          icon={ic_delete}
                          size={18}
                          style={{ color: '#FF5078' }}
                        />{' '}
                        Supprimer
                      </span>
                    )}
                  </div>

                  <InputLabel
                    label='Titre*'
                    width={730}
                    name='contactTitle'
                    value={el.title}
                    placeholder='Titre du contact'
                    onChange={(e) =>
                      handleAdditionalContactTitleChange(e, index)
                    }
                  />
                  <div className='select'>
                    <p>Type de champ</p>
                    <SelectInput
                      // open={selectOpen}
                      // onOpen={() => setSelectOpen(true)}
                      label='Type de champ*'
                      onChange={(e) =>
                        handleAdditionalContactTypeChnage(e, index)
                      }
                      defaultValue='URL'
                      value={el.kind}
                    >
                      <MenuItem value='URL'>URL</MenuItem>
                      <MenuItem value='PHONE'>Téléphone</MenuItem>
                      <MenuItem value='EMAIL'>Email</MenuItem>
                    </SelectInput>
                  </div>
                  <InputLabel
                    // type={el.type === 'PHONE' ? 'tel' : 'text'}
                    label='Valeur du champ*'
                    width={730}
                    name='contactValue'
                    // width={280}
                    rows={20}
                    value={el.value}
                    // error={descriptionError}
                    placeholder='Valeur du champ'
                    onChange={(e) =>
                      handleAdditionalContactValueChange(e, index)
                    }
                  />
                </div>
              ))}
              <div className='additionalContact-action'>
                <Button
                  styled='secondary'
                  onClick={() => handleAddAdditionalContactChange()}
                >
                  <Icon
                    style={{ color: '#FF5078', marginRight: '10px' }}
                    icon={ic_add_circle_outline}
                    size={26}
                  />
                  Ajouter Contact
                </Button>
                <p className='error-message'>{additionalContactError}</p>
              </div>
            </div>
            <div className='section'>
              <h3 className='section-title'>Localisation</h3>
              <div className='section-row'>
                <InputLabel
                  type='number'
                  label='Latitude'
                  name='latitude'
                  placeholder='Latitude...'
                  value={locations[0].latitude}
                  // disabled
                  // error={latitudeError}
                  onChange={(e) => handleLocationEventChange(e, 'lat')}
                />
                <InputLabel
                  type='number'
                  label='Longitude'
                  name='longitude'
                  placeholder='Longitude...'
                  value={locations[0].longitude}
                  // disabled
                  // error={latitudeError}
                  onChange={(e) => handleLocationEventChange(e, 'lng')}
                />
              </div>
              <div className='section-row'>
                <InputLabel
                  label='Message de notification de la localisation'
                  inputType='textArea'
                  rows={6}
                  height={40}
                  width={730}
                  name='notifMessage'
                  value={locations[0].relevantText}
                  placeholder='Message...'
                  onChange={(e) =>
                    handleLocationChange(
                      e.target.value,
                      'locations',
                      'description'
                    )
                  }
                />
              </div>
              <div className='map-section'>
                <MapComponent
                  onChange={handleLocationChange}
                  locations={locations}
                />
              </div>
              <div
                className='section-description'
                ref={(el: any) => {
                  descriptionRef.current = el;
                }}
              >
                {descriptions.map((el: any, index: number) => (
                  <div key={index} className='description'>
                    <div className='description-title'>
                      <h3>Description {index + 1}</h3>
                      {index > 0 && (
                        <span
                          onClick={() => handleDeleteDescriptionChange(index)}
                        >
                          <Icon
                            icon={ic_delete}
                            size={18}
                            style={{ color: '#FF5078' }}
                          />{' '}
                          Supprimer
                        </span>
                      )}
                    </div>

                    <InputLabel
                      label=''
                      width={730}
                      name='descriptionTitle'
                      value={el.title}
                      placeholder='Titre de la description'
                      onChange={(e) => handleDescriptionTitleChange(e, index)}
                    />
                    <InputLabel
                      height={158}
                      inputType='textArea'
                      label=''
                      width={730}
                      name='description'
                      // width={280}
                      rows={20}
                      value={el.value}
                      // error={descriptionError}
                      placeholder='Description'
                      onChange={(e) => handleDescriptionValueChange(e, index)}
                    />
                  </div>
                ))}
                <div className='description-action'>
                  <Button
                    styled='secondary'
                    onClick={() => handleAddDescriptionChange()}
                  >
                    <Icon
                      style={{ color: '#FF5078', marginRight: '10px' }}
                      icon={ic_add_circle_outline}
                      size={26}
                    />
                    Ajouter une description
                  </Button>
                  <p className='error-message'>{descriptionError}</p>
                </div>
              </div>
            </div>
            <div className='section'>
              <div className='section-row'>
                <ExpirationDateSection />
              </div>
            </div>
            <div
              className='section'
              ref={(el: any) => {
                styleRef.current = el;
              }}
            >
              <h3 className='section-title'>Style du wallet</h3>
              <div className='section-row'>
                <ColorPicker
                  label='Couleur du labels (iOS)'
                  color={labelColor}
                  error={labelColorError}
                  onChange={(color: any) => {
                    handleColorChange(color, 'labelColor');
                  }}
                />
                <ColorPicker
                  label="Couleur d'arrière plan (iOS-Android)"
                  color={backgroundColor}
                  error={backgroundColorError}
                  onChange={(color: any) => {
                    handleColorChange(color, 'backgroundColor');
                  }}
                />
                <ColorPicker
                  label='Couleur du texte (iOS)'
                  color={foregroundColor}
                  error={foregroundColorError}
                  onChange={(color: any) => {
                    handleColorChange(color, 'foregroundColor');
                  }}
                />
              </div>

              <h3 className='section-title'>Image*</h3>
              <div className='section-row'>
                <InputFile
                  width={200}
                  label='Ajouter un logo de wallet (200px/200px)'
                  image={logo}
                  defaultImage={defaultLogo}
                  error={logoError}
                  onChange={(e) => {
                    handleFileChange(e, 'logo');
                  }}
                ></InputFile>

                <InputFile
                  width={200}
                  label='Ajouter une icône pour les notifications (29px/29px)'
                  image={icon}
                  defaultImage={cover}
                  error={iconError}
                  onChange={(e) => {
                    handleFileChange(e, 'icon');
                  }}
                ></InputFile>
                <InputFile
                  width={200}
                  label='Ajouter une Image de bande (240px*400px)'
                  image={strip}
                  defaultImage={cover}
                  error={stripError}
                  onChange={(e) => {
                    handleFileChange(e, 'strip');
                  }}
                ></InputFile>
              </div>
            </div>
            <p style={{ fontSize: '14px', color: '#8e8fa1' }}>
              *champ obligatoire
            </p>
          </div>
          <div className='section wireframe-section'>
            <RadioGroup
              name='previews'
              value={switchPreview}
              onChange={handlePreviewChange}
              className='radioGroup'
            >
              <FormControlLabel
                value='android'
                control={<Radio />}
                label='Android'
              />
              <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
            </RadioGroup>

            {switchPreview === 'android' && (
              <Wireframe
                backgroundColor={backgroundColor}
                logo={logo}
                name={name}
                strip={strip}
                address={address}
                descriptions={descriptions}
                openingHours={openingHours}
                additionalContacts={additionalContacts}
                barcodeType={barcodeType}
                primaryFields={primaryFields}
              />
            )}

            {switchPreview === 'iOS' && (
              <WireframeIPhone
                backgroundColor={backgroundColor}
                logo={logo}
                name={name}
                textColor={foregroundColor}
                titleColor={labelColor}
                strip={strip}
                address={address}
                descriptions={descriptions}
                openingHours={openingHours}
                additionalContacts={additionalContacts}
                facebook={facebook}
                linkedin={linkedin}
                website={website}
                businessPhone={businessPhone}
                servicePhone={servicePhone}
                instagram={instagram}
                barcodeType={barcodeType}
                label={label}
                value={value}
                labelPersonalizedText={labelPersonnalizedText}
                valuePersonalizedText={valuePersonnalizedText}
                primaryFields={primaryFields}
              />
            )}
          </div>
        </div>
      </Fragment>
    </FormWrapper>
  );
};

const mapStateToProps = ({ wallet }: RootState) => {
  const {
    id,
    name,
    logo,
    icon,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    businessPhone,
    servicePhone,
    additionalContacts,
    address,
    facebook,
    instagram,
    linkedin,
    locations,
    website,
    openingHours,
    descriptions,
    username,
    email,
    login,
    communicationEmail,
    password,
    primaryFields,
    label,
    value,
    labelPersonnalizedText,
    valuePersonnalizedText,

    confirmPassword,
    emailingConfig,
    nameError,
    logoError,
    iconError,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    addressError,
    openingHoursError,
    usernameSMSerror,
    passwordSMSerror,
    businessPhoneError,
    facebookError,
    instagramError,
    linkedinError,
    websiteError,
    usernameError,
    emailError,
    passwordError,
    confirmPasswordError,
    descriptionError,
    allowedPasses,
    title,
    button,
    expirationDate,
    period,
    periodLength,
    distributionUpdateStrategy,
    expirationType,
    barcodeType,
    //loading
    loading,
    walletInfoSectionHasErrors,
    styleSectionHasErrors,
    accountSectionHasErrors,
    descriptionSectionHasErrors,
    contactSectionHasErrors,
    smsErrors,
    additionalContactError,
    usernameSMS,
    passwordSMS,
    errorModal,
  } = wallet;
  return {
    id,
    name,
    logo,
    icon,
    strip,
    allowedPasses,
    labelColor,
    backgroundColor,
    foregroundColor,
    address,
    facebook,
    instagram,
    primaryFields,
    label,
    value,
    labelPersonnalizedText,
    valuePersonnalizedText,

    passwordError,
    linkedin,
    website,
    openingHours,
    businessPhone,
    servicePhone,
    descriptions,
    additionalContacts,
    username,
    email,
    locations,
    login,
    communicationEmail,
    emailingConfig,
    password,
    confirmPassword,
    expirationDate,
    period,
    periodLength,
    barcodeType,
    distributionUpdateStrategy,
    expirationType,
    nameError,
    logoError,
    iconError,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    walletInfoSectionHasErrors,
    styleSectionHasErrors,
    accountSectionHasErrors,
    descriptionSectionHasErrors,
    contactSectionHasErrors,
    smsErrors,
    businessPhoneError,
    addressError,
    openingHoursError,
    facebookError,
    instagramError,
    linkedinError,
    websiteError,
    usernameError,
    emailError,
    usernameSMSerror,
    passwordSMSerror,
    confirmPasswordError,
    descriptionError,
    additionalContactError,
    usernameSMS,
    passwordSMS,
    //Label
    title,
    //Button
    button,
    //loading
    loading,
    errorModal,
  };
};
export const connector = connect(mapStateToProps, {
  walletFormPropsChanged,
  walletFormCreateWallet,
  walletFormEditWallet,
});

export default connector(WalletForm);
