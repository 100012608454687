import React from 'react';
import styled from 'styled-components';
import { inputStyles } from './input.styles';

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  width?: number;
  value?: string;
  error?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const Input = styled.input<InputProps>`
  ${(props) => inputStyles(props)}
`;

export default Input;
