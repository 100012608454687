import styled from 'styled-components';

export const Wrapper = styled('div')<{
  backgroundColor: any;
  mainContentTextColor: string;
}>`
         display: flex;
         flex-direction: column;
         width: 100%;
         height: 100vh;
         font-family: 'Google Sans', Roboto, Arial, sans-serif !important;
         background-color: #201f24;
         border-radius: 10px;
         overflow-y: scroll;
         padding: 10px;
         box-sizing: border-box;
         align-items: center;
         justify-content: flex-start;

         .rectangularCode {
           width: 213px;
           height: 64px;
         }
            .squareCode {
           width: 113px !important;
           height: 113px !important;
         }

         .barcodeStretch {
           height: 124px !important;
         }
         .hidden {
           display: none;
          }

           .mainSection {
             background-color: ${(props) => props.backgroundColor.hex};
             width: 100%;
             border-radius: 10px;
             padding: 10px;
             display: flex;
             box-sizing: border-box;
             flex-direction: column;
             height: fit-content;
             align-items: center;
             .mainSection-header {
               display: flex;
               justify-content: space-between;
               width: 100%;
             }
             .mainSection-header-logo {
               -webkit-align-items: center;
               align-items: center;
               display: -webkit-box;
               display: -webkit-flex;
               display: flex;
               height: 64px;
               -webkit-justify-content: center;
               justify-content: center;
               margin-bottom: 14px;
               img {
                 background: #fff;
                 -webkit-border-radius: 50%;
                 border-radius: 50%;
                 height: 64px;
                 width: 64px;
               }
             }
           }
           .mainSection-content-banner {
             height: 100px;
             width: 270px;
             overflow: hidden;
             border-radius: 3px;
             margin-top: 20px;
             img {
               object-fit: contain;
               height: 100px;
               width: 270px;
             }
           }
           .mainSection-content-enseigne {
             display: flex;
             margin-top: 20px;
             flex-direction: column;
             align-items: center;
             gap: 5px;
             h4 {
               font-weight: 300;
               text-align: center;
               color: ${(props) => props.mainContentTextColor};
               margin: 0;
               word-break: break-all;
             }
             h3 {
               font-size: 1.375rem;
               font-weight: 400;
               letter-spacing: 0;
               line-height: 1.75rem;
               line-height: 22px;
               margin-top: 0px;
               color: ${(props) => props.mainContentTextColor};
             }
           }
           .mainSection-content-datePoints {
             display: flex;
             margin-top: 20px;
             justify-content: space-between;
             width: 100%;
             align-items: center;
            .alignRight{
             text-align: right;
              }
             .alignLeft{
              text-align: left;
              }
    
           }
           .mainSection-content-barCode {
             margin-top: 20px;
             .barCode {
               width: 270px;
               background: #ffffff;
               height: 72px;
               display: flex;
               border-radius: 3px;
               justify-content: center;
               align-items: center;
               img {
                 width: 213px;
                 height: 64px;
               }
             }
             .walletId {
               font-size: 12px;
               color: ${(props) => props.mainContentTextColor};
               text-align: center;
               margin: 7px 0px 10px 0px;
             }
           }
           .mainSection-label {
             color: ${(props) => props.mainContentTextColor};
             font-weight: 300;
             text-transform: uppercase;
             font-size: 12px;
             letter-spacing: 0.1em;
             margin: 0px;
           }
           .mainSection-data {
             color: ${(props) => props.mainContentTextColor};
             font-size: 16px;
             font-weight: 400;
             margin: 0px;
           }
         }
         .dataSection {
           width: 100%;
           display: flex;
           flex-direction: column;
           justify-content: flex-start;
           align-items: flex-start;
           box-sizing: border-box;
           padding: 0px 10px 20px 10px;
           .sectionIcon {
             margin-top: 20px;
             gap: 10px;
             align-items: center;
             display: flex;
             .data {
               color: #ffffff;
               font-weight: 500;
               font-size: 14px;
               margin: 0px;
               word-break: break-all;
             }
           }
           .sectionLabel {
             margin-top: 20px;
             .label {
               color: #999999;
               font-weight: 500;
               text-transform: uppercase;
               font-size: 12px;
               letter-spacing: 0.075em;
               margin: 0px;
             }
             .data {
               color: #ffffff;
               font-weight: 500;
               font-size: 14px;
               margin: 0px;
               word-break: break-all;
             }
           }
         }
       `;
