import { css } from 'styled-components';
import { lighten, darken } from 'polished';

export const buttonStyles = (props: any) => {
  let height = props.height ? props.height : 'auto';
  let width = props.width ? props.width : 'max-content';
  let background =
    'linear-gradient(140deg, #4814f4 0%, #f75aa0 50%, #feae6e 100%)'; /*props.theme.secondary;*/
  let color = '#FFF';
  let borderRadius = `15px`;
  let padding = '12px 40px 12px 40px';
  let margin = props.margin ? props.margin : '0px';
  let fontSize = 16;
  let fontWeight = '500';
  let border = '0';

  // Hover cases
  let hoverCss = css`
    background: ${darken(0.1, '#ffffff')};
    color: ${lighten(0.1, '#454545')};
    border: 1px solid ${lighten(0.1, '#454545')};
  `;
  if (props.theme) {
    if (props.styled) {
      if (props.outline) {
        hoverCss = css`
          background: ${lighten(0.27, props.theme[props.styled])};
          color: ${props.styled !== 'white'
            ? lighten(0.05, props.theme[props.styled])
            : props.theme.primary};
          border: 1px solid ${lighten(0.05, props.theme[props.styled])};
        `;
      } else if (props.link) {
        hoverCss = css`
          background: transparent;
          color: ${lighten(0.1, props.theme[props.styled])};
          border: none;
        `;
      } else {
        hoverCss = css`
          background: ${darken(0.04, props.theme[props.styled])};
          color: #ffffff;
          border: 1px solid ${darken(0.08, props.theme[props.styled])};
        `;
      }
    }
  }
  if (props.styled.includes('primary')) {
    background = 'linear-gradient(265.47deg, #FC9877 3.98%, #FF5078 96.03%)';
    border = '0';
    color = '#FFF';
  } else if (props.styled.includes('secondary')) {
    background = '#FFFFFF';
    border = '1px solid #FF5078';
    color = '#FF5078';
  }
  // !END Hover cases

  // Click cases
  let clickCss = css`
    background: ${darken(0.2, '#ffffff')};
    color: ${darken(0.2, '#454545')};
    border: 1px solid ${darken(0.2, '#454545')};
  `;
  if (props.theme) {
    if (props.styled) {
      if (props.outline) {
        clickCss = css`
          background: ${lighten(0.13, props.theme[props.styled])};
          color: ${darken(0.1, props.theme[props.styled])};
          border: 1px solid ${darken(0.1, props.theme[props.styled])};
        `;
      } else if (props.link) {
        clickCss = css`
          background: transparent;
          color: ${darken(0.2, props.theme[props.styled])};
          border: none;
        `;
      } else {
        clickCss = css`
          background: #ffffff;
          color: ${props.theme[props.styled]};
          border: 1px solid ${props.theme[props.styled]};
        `;
      }
    }
  }
  // !END Click cases

  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${background};
    border-radius: ${borderRadius};
    border: ${border};
    box-sizing: border-box;
    color: ${color};
    cursor: pointer;
    font-size: ${fontSize}px;
    font-weight: ${fontWeight};
    font-style: normal;
    height: ${height}px;
    width: ${width};
    min-width: 100px;
    margin: ${margin};
    max-width: 100%;
    padding: ${padding};
    text-align: center;
    text-decoration: none;
    transition: all;
    transition-duration: 0.3s;
    white-space: nowrap;
    outline: none;
    position: relative;
    box-shadow: none;
    :hover {
      /* hoverCss */
      filter: brightness(120%);
      transition: all 0.4s ease-in-out;
    }
    /* :active {
      ${clickCss}
    } */
  `;
};
