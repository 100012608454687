import * as React from 'react';
import styled from 'styled-components';
import Input from '../Input';
import TextArea from '../InputTextArea';
import { getInputLabelStyles } from './inputLabel.styles';

export type InputLabelProps = {
  type?: string;
  label: string;
  error?: string;
  inputType?: 'textArea' | 'input';
  className?: string;
  name?: string;
  width?: number;
  height?: number;
  value?: any;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  min?: number;
  max?: number;
  fullWitdh?: boolean;
};

const InputLabel: React.FC<InputLabelProps> = (props) => {
  const { label, error, inputType, className, ...InputProps } = props;

  return (
    <Wrapper className={className}>
      <span className='label'>{label}</span>
      {inputType === 'textArea' ? (
        <TextArea {...InputProps} />
      ) : (
        <Input {...InputProps} />
      )}

      <span className='input-text-error'>{error}</span>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => getInputLabelStyles(props)}
`;
export default InputLabel;
