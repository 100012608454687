import axios from 'axios';
import { URL, API_KEY, API_KEY_VALUE } from './config';
import { GetWalletApiResponse, GetWalletApiError } from './ApiTypes';

export const getWalletByIdentifierQr = (
  identifierQr: String,
  callback: any
) => {
  const requestURL = `${URL}/wallets/detail/${identifierQr}`;
  const key: any = API_KEY;
  const value: string | undefined = API_KEY_VALUE;
  return axios
    .get(requestURL, {
      headers: {
        [key]: value,
      },
    })
    .then(({ data }: { data: Array<GetWalletApiResponse> }) => {
      callback(null, data);
    })
    .catch((error: GetWalletApiError) => {
      callback(error, null);
    });
};

export const addCustumerToWallet = (walletId: String, props: any) => {
  const requestURL = `${URL}/customers`;
  const key: any = API_KEY;

  const value: string | undefined = API_KEY_VALUE;

  let barcodeValue = null;
  if (props.barcode !== '') {
    barcodeValue = props.barcode;
  }
  let phoneNumberValue = null;
  if (props.phoneNumber) {
    let PhoneNumberInitalValue = props.phoneNumber.trim();
    if (PhoneNumberInitalValue !== '') {
      phoneNumberValue = PhoneNumberInitalValue;
    }
  }
  return axios
    .post(
      requestURL,
      {
        firstName: props.firstName,
        lastName: props.lastName,
        email: props.email,
        barcode: barcodeValue,
        phoneNumber: phoneNumberValue,
        source: 'external',
        wallet: walletId,
      },
      {
        headers: {
          // [API_KEY]: API_KEY_VALUE,
          [key]: value,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const addCustomerDevice = (
  walletId: string,
  deviceId: string,
  customerId: string,
  kindId: string | null
) => {
  const requestUrl = `${URL}/customers/devices`;
  const key: any = API_KEY;
  const value: string | undefined = API_KEY_VALUE;
  return axios
    .post(
      requestUrl,
      {
        customerId: customerId,
        walletId: walletId,
        deviceId: deviceId,
        kindId,
      },
      {
        headers: {
          [key]: value,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
