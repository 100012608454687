import { getSettings, updatePassword } from '../apis/settingsApi';
import {
  SETTINGS_PROPS_CHANGED,
  SETTINGS_VALIDATION_PROPS,
  SETTINGS_INITIAL_STATE,
  AppThunk,
  SNACKBAR_SUCCESS,
  SETTINGS_GET_WALLET_SETTINGS,
} from './types';
import {
  SettingsPropsChangedAction,
  SettingsState,
  SettingsInitialStateAction,
} from '../modules/Settings/settings.types';

import { validateFieldsSettingsUpdatePassword } from '../modules/Settings/settings.utils';

import { SettingsApiResponse } from '../apis/ApiTypes';

export const settingsPropsChanged = (
  prop: string,
  value: any
): SettingsPropsChangedAction => {
  return { type: SETTINGS_PROPS_CHANGED, prop, value };
};

export const getWalletSettings = (walletId: string): AppThunk => (dispatch) => {
  getSettings(walletId).then((res: SettingsApiResponse) => {
    dispatch({
      type: SETTINGS_GET_WALLET_SETTINGS,
      payload: res.response.data,
    });
  });
};

export const settingsConfirmUpdatePassword = (
  userId: string,
  props: SettingsState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsSettingsUpdatePassword(props);
  dispatch({ type: SETTINGS_VALIDATION_PROPS, newState });
  if (valid) {
    dispatch({
      type: SETTINGS_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    updatePassword(userId, props).then((res: any) => {
      if (res.status === 200) {
        dispatch({
          type: SETTINGS_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'Les paramètres sont sauvegardés avec succès',
        });
        callback();
      } else if (res.status === 401) {
        dispatch({
          type: SETTINGS_PROPS_CHANGED,
          prop: 'oldPasswordError',
          value: 'Votre mot de passe actuel est incorrect.',
        });
        dispatch({
          type: SETTINGS_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const settingsInitialState = (): SettingsInitialStateAction => {
  return { type: SETTINGS_INITIAL_STATE };
};
