import { getWallets } from '../apis/walletsApi';
import { removeWallet } from '../apis/wallletApi';
import {
  WALLETS_GET_LIST_WALLETS,
  WALLETS_SET_WALLET,
  WALLETS_DELETE_WALLET,
  WALLETS_DELETE_WALLET_LOADING,
  AppThunk,
  WALLETS_GET_SMS_SETTINGS,
} from './types';
import { URL_IMAGE } from '../apis/config';
import { SettingsApiResponse, WalletApiResponse } from '../apis/ApiTypes';
import { getSettings } from '../apis/settingsApi';

export const getWalletsList = (): AppThunk => {
  return (dispatch) => {
    getWallets().then(
      (response: Array<WalletApiResponse>) => {
        dispatch({ type: WALLETS_GET_LIST_WALLETS, payload: response });
      },
      (err) => {
        throw new Error(err);
      }
    );
  };
};

const getDuration = (validity: any): string[] =>
  validity.expirationAfterPeriod
    ? validity.expirationAfterPeriod.split(',')
    : ['1', 'YEARS'];

//Dispatch on Wallet Reducer
export const setWallet = (wallet: WalletApiResponse): AppThunk => (
  dispatch
) => {
  getSettings(wallet._id).then((res: SettingsApiResponse) => {
    if (res.response.data) {
      dispatch({
        type: WALLETS_GET_SMS_SETTINGS,
        payload: {
          usernameSMS: res.response.data.SMSCredentials.username,
          passwordSMS: res.response.data.SMSCredentials.password,
          emailingConfig: res.response.data.emailingConfig,
          allowedPasses: res.response.data.allowedPasses,
        },
      });
    }
  });
  const newWallet = {
    id: wallet._id,
    name: wallet.name,
    logo: `${URL_IMAGE}/${wallet.logoPictureUrl}?${Date.now()}`,
    icon: `${URL_IMAGE}/${wallet.iconPictureUrl}?${Date.now()}`,
    strip: `${URL_IMAGE}/${wallet.stripPictureUrl}?${Date.now()}`,
    labelColor: {
      hex: wallet.labelColor.hex,
      rgb: wallet.labelColor.rgb,
    },
    backgroundColor: {
      hex: wallet.backgroundColor.hex,
      rgb: wallet.backgroundColor.rgb,
    },
    additionalContacts:
      wallet.additionalContacts && wallet.additionalContacts.length !== 0
        ? wallet.additionalContacts
        : [{ title: '', kind: 'URL', value: '' }],
    locations: wallet.locations
      ? wallet.locations
      : [
          {
            longitude: 2.3125185928956062,
            latitude: 49.07575430649549,
            relevantText: '',
          },
        ],
    businessPhone: wallet.businessPhone,
    servicePhone: wallet.servicePhone,
    foregroundColor: {
      hex: wallet.foregroundColor.hex,
      rgb: wallet.foregroundColor.rgb,
    },
    address: wallet.address,
    website: wallet.website,
    facebook:
      wallet.socialMedia && wallet.socialMedia.facebook
        ? wallet.socialMedia.facebook
        : '',
    instagram:
      wallet.socialMedia && wallet.socialMedia.instagram
        ? wallet.socialMedia.instagram
        : '',
    linkedin:
      wallet.socialMedia && wallet.socialMedia.linkedin
        ? wallet.socialMedia.linkedin
        : '',
    openingHours: wallet.openingHours,
    descriptions:
      wallet.descriptions && wallet.descriptions.length !== 0
        ? wallet.descriptions
        : [
            {
              title: '',
              value: '',
            },
          ],
    button: 'Modifier',
    title: 'Modifier Wallet',
    login: wallet.login,
    communicationEmail: wallet.communicationEmail,
    email: wallet.email,
    expirationDate: wallet.validity?.expirationDate,
    periodLength: getDuration(wallet.validity)[0],
    period: getDuration(wallet.validity)[1],
    expirationType: wallet.validity?.expirationType,
    hiddenFields: wallet.hiddenFields,
    iosHeaderFields: wallet.iosHeaderFields,
    //errors
    confirmPasswordError: '',
    additionalContactError: '',
    nameError: '',
    logoError: '',
    iconError: '',
    stripError: '',
    labelColorError: '',
    backgroundColorError: '',
    foregroundColorError: '',
    addressError: '',
    openingHoursError: '',
    facebookError: '',
    instagramError: '',
    linkedinError: '',
    websiteError: '',
    usernameError: '',
    usernameSMSerror: '',
    passwordSMSerror: '',
    emailError: '',
    passwordError: '',
    descriptionError: '',
    businessPhoneError: '',
  };
  dispatch({ type: WALLETS_SET_WALLET, payload: newWallet });
};

export const setNewWallet = () => {
  const newWallet = {
    id: null,
    name: '',
    logo: null,
    icon: null,
    strip: null,
    labelColor: {
      hex: '#000000',
      rgb: { r: 0, g: 0, b: 0, a: 100 },
    },
    backgroundColor: {
      hex: '#FFFFFF',
      rgb: { r: 255, g: 255, b: 255, a: 100 },
    },
    foregroundColor: {
      hex: '#000000',
      rgb: { r: 0, g: 0, b: 0, a: 100 },
    },
    address: '',
    username: '',
    password: '',
    passwordSMS: '',
    usernameSMS: '',
    emailingConfig: 'SES',
    confirmPassword: '',
    website: '',
    locations: [
      {
        longitude: 2.3125185928956062,
        latitude: 49.07575430649549,
        relevantText: '',
      },
    ],
    businessPhone: '',
    servicePhone: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    openingHours: '',
    additionalContacts: [{ title: '', kind: 'URL', value: '' }],
    descriptions: [
      {
        title: '',
        value: '',
      },
    ],
    button: 'Sauvegarder',
    title: 'Nouveau Wallet',
    login: '',
    communicationEmail: '',
    email: '',
    hiddenFields: [],
    iosHeaderFields: {
      value: 'TYPE',
      label: 'REWARDS',
    },
    //errors
    confirmPasswordError: '',
    additionalContactError: '',
    nameError: '',
    logoError: '',
    iconError: '',
    stripError: '',
    labelColorError: '',
    backgroundColorError: '',
    foregroundColorError: '',
    addressError: '',
    openingHoursError: '',
    facebookError: '',
    instagramError: '',
    linkedinError: '',
    websiteError: '',
    usernameError: '',
    emailError: '',
    passwordError: '',
    descriptionError: '',
    businessPhoneError: '',
    usernameSMSError: '',
    passwordSMSError: '',
    errorModal: false,
  };
  return { type: WALLETS_SET_WALLET, payload: newWallet };
};

export const deleteWallet = (walletId: string): AppThunk => (dispatch) => {
  dispatch({
    type: WALLETS_DELETE_WALLET_LOADING,
    payload: true,
  });
  removeWallet(walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: WALLETS_DELETE_WALLET,
        payload: walletId,
      });
    }
  });
};
