import axios from 'axios';
import { URL } from './config';

export const forgotPassword = (username: string) => {
  const requestURL = `${URL}/users/forgot_password`;

  return axios
    .post(requestURL, {
      username,
      role: 'admin',
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
