import { createWallet, updateWallet } from '../apis/wallletApi';
import {
  WALLET_FORM_PROPS_CHANGED,
  WALLET_FORM_WALLET,
  WALLETS_ADD_WALLET_TO_LIST,
  WALLETS_UPDATE_WALLET_TO_LIST,
  // WALLET_FORM_CREATE_WALLET_FAILED,
  WALLET_FORM_INITIAL_STATE,
  AppThunk,
  SNACKBAR_SUCCESS,
} from './types';
import {
  validateFieldsCreateWallet,
  newWalletObject,
  validateFieldsUpdateWallet,
  updateWalletObject,
} from '../modules/WalletForm/walletForm.utils';
import { handleDuplicateFieldsDB } from '../utils/validations';

import {
  WalletFormPropsChangedAction,
  WalletFormInitalStateAction,
} from '../modules/WalletForm/walletForm.types';

export const walletFormPropsChanged = (
  prop: string,
  value: any
): WalletFormPropsChangedAction => {
  return { type: WALLET_FORM_PROPS_CHANGED, prop, value };
};
export const walletFormInitalState = (): WalletFormInitalStateAction => {
  return { type: WALLET_FORM_INITIAL_STATE };
};

export const walletFormCreateWallet = (
  props: any,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsCreateWallet(props);
  dispatch({ type: WALLET_FORM_WALLET, newProps });
  const newWallet = newWalletObject(newProps);

  if (valid && newWallet) {
    dispatch({ type: WALLET_FORM_PROPS_CHANGED, prop: 'loading', value: true });
    createWallet(newWallet).then((res) => {
      if (res.data.status === 'success') {
        dispatch({
          type: WALLETS_ADD_WALLET_TO_LIST,
          payload: res.data.response.data.wallet,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'Le wallet a été créé avec succès',
        });
        callback();
      } else if (res.data.status === 'error' && res.data.error.code === 11000) {
        // handleDuplicateFieldsDB(res.data.message);
        dispatch({
          type: WALLET_FORM_PROPS_CHANGED,
          prop: 'identifierError',
          value: handleDuplicateFieldsDB(res.data.message),
        });
      } else if (
        res.status === 400 &&
        res.data.includes('Manager already exists')
      ) {
        dispatch({
          type: WALLET_FORM_PROPS_CHANGED,
          prop: 'usernameError',
          value: "Le nom d'utilisateur existe déjà",
        });

        dispatch({
          type: WALLET_FORM_PROPS_CHANGED,
          prop: 'errorModal',
          value: true,
        });
      } else if (
        res.status === 400 &&
        res.data.includes('Email already exists')
      ) {
        dispatch({
          type: WALLET_FORM_PROPS_CHANGED,
          prop: 'emailError',
          value: "L'adresse email existe déjà",
        });
        dispatch({
          type: WALLET_FORM_PROPS_CHANGED,
          prop: 'errorModal',
          value: true,
        });
      } else if (res.status === 404 && res.data.includes('Not Found')) {
        dispatch({
          type: WALLET_FORM_PROPS_CHANGED,
          prop: 'usernameSMSerror',
          value: "Ce compte n'existe pas",
        });

        dispatch({
          type: WALLET_FORM_PROPS_CHANGED,
          prop: 'errorModal',
          value: true,
        });
      } else if (res.status === 404 && res.data.includes('Bad Request')) {
        dispatch({
          type: WALLET_FORM_PROPS_CHANGED,
          prop: 'usernameSMSerror',
          value: 'Il manque un champ',
        });

        dispatch({
          type: WALLET_FORM_PROPS_CHANGED,
          prop: 'errorModal',
          value: true,
        });
      }
      dispatch({
        type: WALLET_FORM_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    });
  }
};

export const walletFormEditWallet = (
  props: any,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsUpdateWallet(props);
  dispatch({ type: WALLET_FORM_WALLET, newProps });
  const newWallet = updateWalletObject(newProps);

  if (valid && props.id && newWallet) {
    dispatch({ type: WALLET_FORM_PROPS_CHANGED, prop: 'loading', value: true });
    updateWallet(props.id, newWallet).then((res) => {
      switch (res.status) {
        case 200:
          dispatch({
            type: WALLETS_UPDATE_WALLET_TO_LIST,
            payload: res.data.response.data,
          });
          callback();
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'Les modifications ont été sauvegardées avec succès',
          });
          break;

        case 404:
          dispatch({
            type: WALLET_FORM_PROPS_CHANGED,
            prop: 'usernameSMSerror',
            value: 'Compte non trouvé',
          });
          dispatch({
            type: WALLET_FORM_PROPS_CHANGED,
            prop: 'errorModal',
            value: true,
          });
          break;
        case 400:
          dispatch({
            type: WALLET_FORM_PROPS_CHANGED,
            prop: 'usernameSMSerror',
            value: 'Il manque un champ',
          });
          dispatch({
            type: WALLET_FORM_PROPS_CHANGED,
            prop: 'errorModal',
            value: true,
          });
          break;

        case 500:
          dispatch({
            type: WALLET_FORM_PROPS_CHANGED,
            prop: 'usernameSMSerror',
            value: "Can't log in",
          });
          dispatch({
            type: WALLET_FORM_PROPS_CHANGED,
            prop: 'errorModal',
            value: true,
          });
          break;

        default:
          dispatch({
            type: WALLETS_UPDATE_WALLET_TO_LIST,
            payload: res.data.response.data,
          });
          callback();
      }
      dispatch({
        type: WALLET_FORM_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    });
  }
};
/*
} else if (res.data.status === 'error' && res.data.error.code === 11000) {
  // handleDuplicateFieldsDB(res.data.message);
  dispatch({
    type: WALLET_FORM_PROPS_CHANGED,
    prop: 'identifierError',
    value: handleDuplicateFieldsDB(res.data.message),
  });

}*/
